<template>
  <b-tabs
    v-if="selected_entity2"
    v-model="tabIndex"
    id="menu"
    class="position-relative pb-5"
    @input="index => tabTitle = tabs[stereotype][index]"
  >
    <hr class="mt-1" :class="'hr-valid-bar hr'+selected_entity2.context.details.validity">

    <b-tab title="Entity" class="mx-1">
      <EntityTab @clicked="updateGraph" :key="selected_entity2.context.details.id"/>
    </b-tab>

    <b-tab v-if="stereotype === 'Function'" title="Allocation" class="mx-1">
      <AllocationTab />
    </b-tab>

    <b-tab title="Requirements" class="mx-1">
      <RequirementsTab />
    </b-tab>

    <b-tab title="Properties" class="mx-1">
      <PropertiesTab />
    </b-tab>

    <b-tab v-if="stereotype === 'Performer'" title="Interfaces" class="mx-1">
      <InterfacesTab />
    </b-tab>

    <b-tab title="Context" class="mx-1">
      <ContextTab @clicked="updateGraph" />
    </b-tab>

    <b-tab v-if="stereotype === 'Function'" title="Constraints" class="mx-1">
      <ConstraintTab />
    </b-tab>

    <b-tab v-if="stereotype === 'Performer'" title="Compliance" class="mx-1">
      <ComplianceTab />
    </b-tab>

    <b-tab v-if="auth.roles && auth.roles.includes('administrator')" title="Debug">
      <template #title>
        <feather-icon icon="CodeIcon" class="text-danger" />
        <span class="text-danger">Debug</span>
      </template>

      <div class="mx-75">
        <h6 class="text-danger">
          Component Details
        </h6>
        <pre>{{ selected_entity2.context.details }}</pre>

        <h6 class="text-danger">
          Component Full Context
        </h6>
        <pre>{{ selected_entity2 }}</pre>
      </div>
    </b-tab>
  </b-tabs>
  <div v-else class="text-center mt-4">
    <b-spinner />
    <b-card>
      <span class="">Loading...</span>
    </b-card>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import AllocationTab from '@/components/Domain/EditMenuComponents/Allocation.vue'
import InterfacesTab from '@/components/Domain/EditMenuComponents/Interfaces.vue'
import ConstraintTab from '@/components/Domain/EditMenuComponents/FnConstraint.vue'
import ComplianceTab from '@/components/Domain/EditMenuComponents/Compliance.vue'
import EntityTab from './EditMenuComponents/Entity.vue'
import PropertiesTab from './EditMenuComponents/Properties.vue'
import ContextTab from './EditMenuComponents/Context.vue'
import RequirementsTab from './EditMenuComponents/Requirements.vue'

export default {
  components: {
    ComplianceTab,
    ConstraintTab,
    InterfacesTab,
    AllocationTab,
    EntityTab,
    PropertiesTab,
    ContextTab,
    RequirementsTab,
  },
  props: {
    stereotype: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    context: {},
    tabIndex: 0,
    tabTitle: 'Entity',
    tabs: {
      Base: ['Entity', 'Requirements', 'Properties', 'Context'],
      Function: ['Entity', 'Allocation', 'Requirements', 'Properties', 'Context', 'Constraints'],
      Performer: ['Entity', 'Requirements', 'Properties', 'Interfaces', 'Context', 'Compliance'],
    },
    defaultTab: {
      Base: 0,
      Function: 1,
      Performer: 0,
    },
  }),
  computed: {
    ...mapState({
      selected_entity2: state => state.domainModel.selected_entity2,
      auth: state => state.auth,
    }),
  },
  watch: {
    stereotype(newVal, oldVal) {
      // To maintain tab when switching between stereotypes we have to convert the tab title to the correct index
      if (!newVal) return
      const newIndex = this.tabs[newVal].findIndex(title => title === this.tabTitle)
      if (newIndex === -1) {
        this.tabIndex = this.defaultTab[newVal]
        this.tabTitle = this.tabs[newVal][this.tabIndex]
      } else {
        this.tabIndex = newIndex
      }
    },
  },
  methods: {
    updateGraph() {
      this.$emit('clicked')
    },
  },
}
</script>

<style scoped>
.hr-valid-bar {
  width: 100%;
  height: 10px;
  margin: -2rem auto 1rem;
  border-radius: 5px;
}

.hrValid {
  background-color: #37c74f;
}

.hrImplied {
  background-color: #f38717;
}

.hrInvalid {
  background-color: #ef4143;
}
</style>
