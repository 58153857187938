<template>
  <div v-if="selected_entity2" id="domain-entity-context">
    <!-- Component Stereotype -->
    <div class="d-inline-flex w-100 mb-50">
      <p v-if="entityType.length > 0" class="mb-0 mr-1 font-small-3 font-weight-bolder">
        Stereotype{{ entityType.length > 1 ? 's' : '' }}:
      </p>
      <p v-else class="mb-0 mr-1 font-small-3 font-weight-bold text-muted">
        No stereotype
      </p>
      <div
        v-for="(stereotype, index) in entityType"
        :key="index"
        class="mb-50 mr-50"
      >
        <feather-icon
          icon="CircleIcon"
          size="10"
          class="ml-1 mr-50 text-muted"
        />
        <feather-icon
          v-b-tooltip.hover.above="`Component type: ${stereotype}`"
          :icon="entityLabel(stereotype)"
          class="font-small-3 mr-50"
        />
        <span class="font-small-3 font-weight-bolder">{{ stereotype }}</span>
      </div>
    </div>

    <!-- Component Heading -->
    <div class="d-inline-flex w-100 justify-content-between align-items-center">
      <h4
        :title="selected_entity2.context.details.name"
        class="text-primary"
      >
        The Component heading
        {{ selected_entity2.context.details.name }}
      </h4>
    </div>

    <hr class="mb-2">

    <vue-perfect-scrollbar
      :settings="{ maxScrollbarLength: 60, wheelPropagation: false}"
      class="ps-customizer-area scroll-area"
    >
      <div style="height: 60rem">
        <!-- Aliases -->
        <generic-accordion-list-group
          title="aliases"
          :item-count="findAliases().length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top="'Add Alias'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="$bvModal.show('create-alias-modal')"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="(alias, a) in findAliases()"
                :key="a"
                class="d-inline-flex w-100 justify-content-between disabled-item"
              >
                <span class="ml-2 font-weight-bold align-self-center">
                  {{ alias.target_props.name }}
                </span>
                <div>
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.right.noninteractive="'Swap name with alias'"
                    variant="flat-warning"
                    size="sm"
                    @click="swapAliasPrep(alias)"
                  >
                    Swap
                  </b-button>
                  <b-button
                    :title="`Edit alias: ${alias.target_props.name}`"
                    variant="flat-success"
                    class="p-50"
                    @click="showAliasEditModal(alias)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    :title="`Delete alias: ${alias.target_props.name}`"
                    variant="flat-danger"
                    class="p-50"
                    @click="showAliasDeleteModal(alias)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>

        <!-- Instances -->
        <generic-accordion-list-group
          title="instances"
          :item-count="findInstances().length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top.noninteractive="'Add Instance'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="$bvModal.show('create-instance-modal')"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="node in findInstances()"
                :key="node.name"
                class="d-inline-flex w-100 justify-content-between"
              >
                <div class="align-self-center">
                  <span>
                    <span class="text-primary font-weight-bold mr-50">{{ node.name }}</span>
                    <span v-if="node.version" class="font-small-3">(version: {{ node.version }})</span>
                  </span>
                  <p class="mb-0 font-small-3" v-sanitized-html="node.description" />
                </div>

                <div>
                  <b-button
                    :title="`Edit ${node.name}`"
                    variant="flat-success"
                    class="p-50"
                    @click="updateInstance(node)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    :title="`Delete ${node.name}`"
                    variant="flat-danger"
                    class="p-50"
                    @click="deleteInstance(node)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>

        <!-- Relations -->
        <generic-accordion-list-group
          title="relations"
          :item-count="selected_entity2.context.relations.edges.length"
        >
          <template v-slot:addShowButton>
            <b-card-text class="d-inline-flex w-100 justify-content-center">
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  @click="$bvModal.show('add-relation-modal')"
                >
                  Add Relation
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  size="sm"
                  @click="showRelations"
                >
                  Show Relations
                </b-button>
              </b-button-group>
            </b-card-text>
          </template>
          <template v-slot:content>
            <b-card-text>
              <h4 v-if="findRelations2().allocations.length > 0" class="ml-1 mb-0">
                Allocation
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().allocations"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().performances.length > 0" class="ml-1 mb-0">
                Functions Performed
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().performances"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().enables.length > 0" class="ml-1 mb-0 mt-1">
                Enables
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().enables"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().enabled.length > 0" class="ml-1 mb-0 mt-1">
                Enabled by
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().enabled"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().implement.length > 0" class="ml-1 mb-0 mt-1">
                Implements
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().implement"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().implemented.length > 0" class="ml-1 mb-0 mt-1">
                Implemented by
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().implemented"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().benefits.length > 0" class="ml-1 mb-0 mt-1">
                Benefited By
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().benefits"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().benefit.length > 0" class="ml-1 mb-0 mt-1">
                Benefits
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().benefit"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().rscUse.length > 0" class="ml-1 mb-0 mt-1">
                Resources Used/Produced/Consumed
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().rscUse"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().useInFn.length > 0" class="ml-1 mb-0 mt-1">
                Used in Functions
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().useInFn"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
              <h4 v-if="findRelations2().otherRels.length > 0" class="ml-1 mb-0 mt-1">
                Other Relationships
              </h4>
              <b-list-group>
                <b-list-group-item
                  v-for="item in findRelations2().otherRels"
                  :key="item.id"
                  class="row d-flex"
                >
                  <b-col cols="10">
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.source,
                        },
                      }"
                    >
                      {{ item.source_str }}
                    </router-link>
                    {{ item.name }}
                    <router-link
                      :to="{
                        name: 'domain_ontology_focus',
                        query: {
                          focus: item.target,
                        },
                      }"
                    >
                      {{ item.target_str }}
                    </router-link>
                  </b-col>
                  <b-col cols="2">
                    <feather-icon
                      class="mr-1"
                      icon="EditIcon"
                      style="cursor: pointer"
                      @click="showRelEditModal(item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      style="cursor: pointer"
                      @click="showRelDeleteModal(item)"
                    />
                  </b-col>
                </b-list-group-item>
              </b-list-group>
            </b-card-text>
          </template>
        </generic-accordion-list-group>

        <!-- Interfaces -->
        <generic-accordion-list-group
          title="interfaces"
          :item-count="findInterfaces().outputs.length + findInterfaces().inputs.length"
          :on-title-click="searchInterfaces"
        >
          <template v-slot:content>
            <b-list-group>
              <h4>Outputs</h4>
              <b-list-group-item
                v-for="item in findInterfaces().outputs"
                :key="item.id"
                @click="showInterface(item.target_props.id)"
              >
                {{ item.target_props.name }}
                <i class="fas fa-exchange-alt" />
              </b-list-group-item>
              <h4 class="mt-3">
                Inputs
              </h4>
              <b-list-group-item
                v-for="item in findInterfaces().inputs"
                :key="item.id"
                @click="showInterface(item.target_props.id)"
              >
                {{ item.target_props.name }}
                <i class="fas fa-exchange-alt" />
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>

        <!-- Usage as a Resource -->
        <generic-accordion-list-group
          title-prefix="usage as a"
          title="resource"
          :item-count="findResources().count"
        >
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="item in findResources().if_resources"
                :key="item.id"
                @click="showInterface(item.target_props.id)"
              >
                {{ item.target_props.name }}
                <i class="fas fa-exchange-alt" />
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>

        <!-- Other Views -->
        <generic-accordion-list-group
          title-prefix="other"
          title="views"
          :item-count="otherViews.length"
        >
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="item in otherViews"
                style="cursor: pointer"
                :key="item.id"
                @click="openView(item)"
              >
                <span class="d-flex justify-content-between w-100">
                  <span class="text-primary">{{ item.name }}</span>
                  <span class="text-muted">{{ item.created | diffForHumans }}</span>
                </span>
                <i class="fas fa-exchange-alt" />
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>

        <!-- Behaviours -->
        <generic-accordion-list-group
          v-if="!findingBehaviours"
          title-prefix=""
          title="behaviours"
          :item-count="entity_behaviours.count"
        >
          <template v-slot:content>
            <b-list-group>
              <!-- Behaviour Nodes -->
              <div v-for="bnType in bnTypeMap" :key="bnType.name">
                <div
                  v-if="entity_behaviours[bnType.name].length > 0"
                  :id="`context-behaviour-nodes-${bnType.key}`"
                  class="mb-50"
                >
                  <span class="ml-50 text-info font-weight-bolder">{{ bnType.key[0].toUpperCase() + bnType.key.slice(1) }}</span>
                  <b-list-group-item
                    v-for="node in entity_behaviours[bnType.name]"
                    :key="node.id"
                    class="d-inline-flex w-100 justify-content-between"
                  >
                    <span class="font-weight-bold">
                      {{ node.name }}
                    </span>
                    <div v-for="bt in node.bts" :key="bt.id">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        v-b-tooltip.hover.top.window.noninteractive="'View node in ' + bt.name"
                        variant="flat-primary"
                        size="sm"
                        @click="routeToBehaviourView(bt.id, [node.id])"
                      >
                        <feather-icon icon="TargetIcon"/>
                        {{ bt.name }}
                      </b-button>
                    </div>
                  </b-list-group-item>
                </div>
              </div>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
        <div v-else class="text-center mt-4">
          <b-spinner />
          <b-card>
            <span>Fetching Behaviours...</span>
          </b-card>
        </div>

        <!-- Requirements -->
        <!--<generic-accordion-list-group-->
        <!--  title-prefix="associated"-->
        <!--  title="requirements"-->
        <!--  :item-count="selectedReqs.length"-->
        <!--&gt;-->
        <!--  <template v-slot:titleButton>-->
        <!--    <b-button-->
        <!--      v-b-tooltip.hover.top.noninteractive="'Associate Requirement'"-->
        <!--      v-ripple.400="'rgba(40, 199, 111, 0.15)'"-->
        <!--      class="btn-icon"-->
        <!--      variant="flat-success"-->
        <!--      @click="associateReqModal()"-->
        <!--    >-->
        <!--      <feather-icon-->
        <!--        icon="LinkIcon"-->
        <!--      />-->
        <!--    </b-button>-->
        <!--  </template>-->
        <!--  <template v-slot:content>-->
        <!--    <list-group-requirement-->
        <!--      label="Requirements"-->
        <!--      :entity-array="selectedReqs"-->
        <!--      modal="associator-generic-requirements"-->
        <!--      hide-header-->
        <!--    />-->
        <!--  </template>-->
        <!--</generic-accordion-list-group>-->

        <!-- Issues -->
        <generic-accordion-list-group
          title-prefix=""
          title="issues"
          :item-count="selectedIssues.length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top.noninteractive="'Associate Issues'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="associateIssueModal()"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Create Issue'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="issueModalRaise(selected_entity2.context.details.id, 'Component', refreshEntityContext)"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <list-group-issue
              label="Issues"
              :entity-array="selectedIssues"
              modal="associator-generic-issues"
              hide-header
            />
          </template>
        </generic-accordion-list-group>

        <!-- Releases -->
        <generic-accordion-list-group
          title-prefix=""
          title="releases"
          :item-count="findReleases().length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top.noninteractive="'Allocate Release'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="$bvModal.show('allocate-release-modal')"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="rel in findReleases()"
                :key="rel.id"
                class="d-inline-flex w-100 justify-content-between"
                @click="openRelease(rel.id)"
              >
                <div>
                  <span class="font-weight-bold mr-50 text-primary">{{ rel.name }}</span>
                  <span class="text-muted" v-if="rel.end_date && rel.end_date !== ''">
                    - {{ rel.end_date | moment }}
                  </span>
                  <div>
                    {{ rel.description }}
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
      </div>
    </vue-perfect-scrollbar>

    <div id="context-tab-modals">
      <!-- FIXME: The `tempData` strategy is flawed, need to use separate values or cater for undefined errors -->
      <AliasAdd />
      <AliasEdit :data="tempData" />
      <AliasDelete :data="tempData" />
      <InstanceCreateModal @created="refreshEntityContext" />
      <InstanceUpdateModal :instance="tempData" @updated="refreshEntityContext" />
      <InstanceDeleteModal :instance="tempData" @deleted="refreshEntityContext" />
      <RelationEdit :data="tempData" />
      <RelationDelete :data="tempData" />
      <ReleaseAllocate />
      <associator-generic
        name="Issues"
        :associated-items="selectedIssues.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.name.replace(/<\/?[^>]+(>|$)/g, '')}`,
          }
        })"
        }
        :all-items="allIssues.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.name.replace(/<\/?[^>]+(>|$)/g, '')}`,
          }
        })"
        @associated="associateIssueComponent"
      />
      <RelationAdd />
      <swap-alias-modal :name_swap="name_swap" :new_name="alias_name" @swap="doSwap" />
    </div>
  </div>

  <!-- Loading Spinner -->
  <div v-else class="d-inline-flex w-100 justify-content-center">
    <div class="d-flex flex-column">
      <atom-spinner />
      <p class="mt-1">Loading...</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import { mapState } from 'vuex'
import { BCol } from 'bootstrap-vue'
import AliasAdd from '@/components/Domain/Modals/Context/AliasAdd.vue'
import AliasEdit from '@/components/Domain/Modals/Context/AliasEdit.vue'
import AliasDelete from '@/components/Domain/Modals/Context/AliasDelete.vue'
import RelationAdd from '@/components/Domain/Modals/Context/RelationAdd.vue'
import RelationEdit from '@/components/Domain/Modals/Context/RelationEdit.vue'
import RelationDelete from '@/components/Domain/Modals/Context/RelationDelete.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import ReleaseAllocate from '@/components/Domain/Modals/Context/ReleaseAllocate.vue'
import ListGroupIssue from '@/components/Forms/ListGroups/ListGroupIssue.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InstanceCreateModal from '@/components/Domain/Modals/Context/InstanceCreateModal.vue'
import InstanceDeleteModal from '@/components/Domain/Modals/Context/InstanceDeleteModal.vue'
import InstanceUpdateModal from '@/components/Domain/Modals/Context/InstanceUpdateModal.vue'
import SwapAliasModal from '@/components/Domain/Modals/SwapAliasModal.vue'
import GenericAccordionListGroup from '@/components/Generic/GenericAccordionListGroup.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import router from '@/router'

export default {
  components: {
    AtomSpinner,
    GenericAccordionListGroup,
    AliasAdd,
    AliasEdit,
    AliasDelete,
    RelationAdd,
    RelationEdit,
    RelationDelete,
    AssociatorGeneric,
    ReleaseAllocate,
    ListGroupIssue,
    InstanceCreateModal,
    InstanceDeleteModal,
    InstanceUpdateModal,
    SwapAliasModal,
    BCol,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('LL')
    },
  },
  data() {
    return {
      name: '',
      associated_behaviours: {},
      findingBehaviours: null,
      entity_behaviours: {},
      tempData: {},
      tempInterfaces: null,
      allReqs: [],
      selectedReqs: [],
      allIssues: [],
      allInstances: [],
      selectedIssues: [],
      name_swap: {},
      alias_name: '',
      bnTypeMap: [],
      otherViews: [],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapState('behaviours', ['behaviourViews']),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
  },
  watch: {
    selected_cell(newVal) {
      this.name = newVal.name
      this.associated_behaviours = newVal
      this.syncAllocations()
    },
    selected_entity2() {
      this.syncAllocations()
    },
  },
  mounted() {
    this.syncAllocations()
  },
  methods: {
    syncAllocations() {
      this.selectedReqs = this.findRequirements()
      this.selectedIssues = this.findIssues()
      this.otherViews = this.findOtherViews()
      this.findBehaviours()
    },
    // TODO: Prevent opening new tabs
    // Method to circumvent using <router-link> on HTML elements that need href instead.
    // Pass in the name of the route
    routeWindow(link) {
      const routeData = this.$router.resolve({ path: link })
      window.open(routeData.href, '_blank')
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
    showRelEditModal(data) {
      this.tempData = data

      this.$bvModal.show('edit-relation-modal')
    },
    showRelDeleteModal(data) {
      // Deep clone using JSON parse so it creates a new object to use in the modal
      this.tempData = data

      this.$bvModal.show('delete-relation-modal')
    },
    showAliasEditModal(data) {
      // Deep clone using JSON parse so it creates a new object to use in the modal
      this.tempData = JSON.parse(JSON.stringify(data))

      this.$bvModal.show('edit-alias-modal')
    },
    showAliasDeleteModal(data) {
      // Deep clone using JSON parse so it creates a new object to use in the modal
      this.tempData = JSON.parse(JSON.stringify(data))

      this.$bvModal.show('delete-alias-modal')
    },

    showRelations() {
      this.$router.push(
        {
          name: 'ontology_class_diagram_root',
          params: { root: this.selected_entity2.context.details.id },
        },
      )
    },

    findAliases() {
      const rels = this.selected_entity2.context.relationships
      const output = []

      for (let i = 0; i < rels.length; i++) {
        if (rels[i].labels.includes('Alias')) {
          output.push(rels[i])
        }
      }

      return output
    },

    swapAliasPrep(alias) {
      this.alias_name = alias.target_props.name
      if (this.selected_entity2.context.details.name !== this.name
        && this.selected_entity2.context.labels
        && !this.selected_entity2.context.labels.includes('Function')) {
        const params = {
          model: this.$store.state.model.id,
          new_name: alias.target_props.name,
        }
        this.$http
          .get(`/api/v2/domain_model/get_impacted_fns/${this.selected_entity2.context.details.id}`, { params })
          .then(({ data }) => {
            console.log('Possible Impacts: ', data)
            this.name_swap = data
            if (
              this.name_swap.functions
              && this.name_swap.functions.length === 0
              && this.name_swap.requirements
              && this.name_swap.requirements.length === 0
            ) {
              this.doStraightSwap()
            } else {
              this.$bvModal.show('swap-alias-modal')
            }
          })
          .catch(error => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred when fetching impacted functions for the alias',
                text: `${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.doStraightSwap()
      }
    },
    doSwap(replacements) {
      this.$bvModal.hide('swap-alias-modal')
      this.doStraightSwap()
      const payload = {
        model: this.$store.state.model.id,
        replacements,
      }
      this.$http
        .post('/api/v2/domain_model/rename_impacted_things', payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        })
    },
    doStraightSwap() {
      this.$bvModal.hide('swap-alias-modal')
      const payload = {
        alias_name: this.alias_name,
        model: this.$store.state.model.id,
      }
      this.$http
        .post(`/api/v2/domain_model/swap_alias_name/${this.selected_entity2.context.details.id}`, payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
            .then(() => {
              this.$bvModal.hide('swap-alias-modal')
              this.$emit('clicked')

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Swapped Alias',
                  icon: 'CheckIcon',
                  text: `Updated Component ${this.name}`,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
            })
            .catch(error => {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred when fetching the selected entity',
                  text: `${error}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when swapping the alias',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    // Find related components that are children and not the parent
    findChildren() {
      const rels = this.selected_entity2.context.relationships
      const parent = this.selected_entity2.context.parent_rel?.parent

      const output = []
      // Sort through all rels, find related components
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].rel_type === 'TYPE_OF' || rels[i].rel_type === 'PART_OF') {
          if (rels[i].target_props.id !== parent) {
            // Find related comps that aren't parents
            output.push({
              name: rels[i].target_props.qualified_name,
              id: rels[i].target_props.id,
              rel_type: rels[i].rel_type,
            })
          }
        }
      }
      return output
    },

    findRelsByLabel(label) {
      return this.selected_entity2.context.relationships
        .filter(r => r.labels.includes(label))
        .map(r => r.target_props)
    },

    findIssues() {
      return this.findRelsByLabel('Issue')
    },

    findInstances() {
      return this.findRelsByLabel('Instance')
    },

    findReleases() {
      return this.findRelsByLabel('Release')
    },

    findRequirements() {
      return this.findRelsByLabel('Requirement')
    },

    // Find all other views component exists in
    findOtherViews() {
      return this.findRelsByLabel('ClassDiagram')
    },

    openView(item) {
      this.$store.commit('app/SET_DYNAMIC_PAGE_TITLE', item.name)
      router.push({
        name: 'class_diagram',
        params: { diagramId: item.id },
        query: { focus: this.selected_entity2.context.details.id },
      })
    },

    openRelease(release) {
      router.push({
        name: 'model_releases',
        query: { selected: release },
      })
    },

    // TODO: Find a better way to output sorted data
    // Find all relations that are behaviours
    async findBehaviours() {
      this.findingBehaviours = true
      const { behaviours } = this.selected_entity2.context

      const nodeTypeKeyMap = [
        { name: 'Event', key: 'events' },
        { name: 'State', key: 'states' },
        { name: 'Selection', key: 'selections' },
        { name: 'GuardedEvent', key: 'guards' },
        { name: 'Input', key: 'inputs' },
        { name: 'Output', key: 'outputs' },
        { name: 'Assertion', key: 'assertions' },
        { name: 'Quantification', key: 'quantifications' },
        { name: 'Quantity', key: 'quantity' },
        { name: 'FunctionNode', key: 'functions' },
      ]

      this.entity_behaviours = behaviours
      this.bnTypeMap = nodeTypeKeyMap
      this.findingBehaviours = false
    },

    // Find all relations that are rels between nodes
    findRelations() {
      const rels = this.selected_entity2.context.relations.edges
      const compID = this.selected_entity2.context.details.id
      let text = ''
      const output = []

      for (let i = 0; i < rels.length; i++) {
        // Check for relation text
        if (rels[i].rel_textual_form) {
          text = rels[i].rel_textual_form
        } else {
          // If there is no rel text.
          text = `Undefined. Name: ${rels[i].name}`
        }
        output.push({
          text,
          id: rels[i].rel_id,
          compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
          entity_id: compID,

          name: rels[i].name,

          source_str: rels[i].source_str,
          source: rels[i].source,
          source_multiplicity: rels[i].properties.source_multiplicity,
          source_label: rels[i].properties.source_label,
          source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

          target_str: rels[i].target_str,
          target: rels[i].target,
          target_multiplicity: rels[i].properties.target_multiplicity,
          target_label: rels[i].properties.target_label,
          target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
        })
      }

      return output
    },

    // Find all relations that are rels between nodes
    findRelations2() {
      const rels = this.selected_entity2.context.relations.edges
      const compID = this.selected_entity2.context.details.id
      let text = ''
      const allocations = []
      const performances = []
      const enables = []
      const enabled = []
      const implement = []
      const implemented = []
      const rscUse = []
      const useInFn = []
      const otherRels = []
      const benefit = []
      const benefits = []

      for (let i = 0; i < rels.length; i++) {
        // Check for relation text
        if (rels[i].rel_textual_form) {
          text = rels[i].rel_textual_form
        } else {
          // If there is no rel text.
          text = `Undefined. Name: ${rels[i].name}`
        }
        if (rels[i].name.toLowerCase() === 'performs') {
          if (rels[i].target === compID) {
            allocations.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          } else if (rels[i].source === compID) {
            performances.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          }
        } else if (rels[i].name.toLowerCase() === 'allocated to') {
          allocations.push({
            text,
            id: rels[i].rel_id,
            compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
            entity_id: compID,

            name: rels[i].name,

            source_str: rels[i].source_str,
            source: rels[i].source,
            source_multiplicity: rels[i].properties.source_multiplicity,
            source_label: rels[i].properties.source_label,
            source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

            target_str: rels[i].target_str,
            target: rels[i].target,
            target_multiplicity: rels[i].properties.target_multiplicity,
            target_label: rels[i].properties.target_label,
            target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
          })
        } else if (rels[i].name.toLowerCase() === 'benefits') {
          if (rels[i].target === compID) {
            benefits.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          } else if (rels[i].source === compID) {
            benefit.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          }
        } else if (['implements', 'contributes to'].includes(rels[i].name.toLowerCase())) {
          if (rels[i].target === compID) {
            implemented.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          } else if (rels[i].source === compID) {
            implement.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          }
        } else if (rels[i].name.toLowerCase() === 'enabled by') {
          if (rels[i].target === compID) {
            enables.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          } else if (rels[i].source === compID) {
            enabled.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          }
        } else if (rels[i].name.toLowerCase() === 'uses') {
          if (rels[i].target === compID) {
            useInFn.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          } else if (rels[i].source === compID) {
            rscUse.push({
              text,
              id: rels[i].rel_id,
              compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
              entity_id: compID,

              name: rels[i].name,

              source_str: rels[i].source_str,
              source: rels[i].source,
              source_multiplicity: rels[i].properties.source_multiplicity,
              source_label: rels[i].properties.source_label,
              source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

              target_str: rels[i].target_str,
              target: rels[i].target,
              target_multiplicity: rels[i].properties.target_multiplicity,
              target_label: rels[i].properties.target_label,
              target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
            })
          }
        } else {
          otherRels.push({
            text,
            id: rels[i].rel_id,
            compID: rels[i].source === compID ? rels[i].target : rels[i].source, // Check for a component ID that differs from selected component
            entity_id: compID,

            name: rels[i].name,

            source_str: rels[i].source_str,
            source: rels[i].source,
            source_multiplicity: rels[i].properties.source_multiplicity,
            source_label: rels[i].properties.source_label,
            source_route: `/model/ontology/?root=${rels[i].source}&focus=${rels[i].source}`,

            target_str: rels[i].target_str,
            target: rels[i].target,
            target_multiplicity: rels[i].properties.target_multiplicity,
            target_label: rels[i].properties.target_label,
            target_route: `/model/ontology/?root=${rels[i].target}&focus=${rels[i].target}`,
          })
        }
      }

      const count = allocations.length
        + performances.length
        + benefits.length
        + benefit.length
        + rscUse.length
        + useInFn.length
        + otherRels.length

      return {
        count,
        allocations,
        performances,
        enables,
        enabled,
        implement,
        implemented,
        benefits,
        benefit,
        rscUse,
        useInFn,
        otherRels,
      }
    },

    // Find all relations that are interfaces
    findInterfaces() {
      const rels = this.selected_entity2.context.relationships
      const output = {
        outputs: [],
        inputs: [],
      }

      for (let i = 0; i < rels.length; i++) {
        if (rels[i].labels.includes('Interface')) {
          if (rels[i].rel_type === 'SOURCE') {
            output.outputs.push(rels[i])
          } else if (rels[i].rel_type === 'DESTINATION') {
            output.inputs.push(rels[i])
          }
        }
      }

      return output
    },

    searchInterfaces() {
      const compID = this.selected_entity2.context.details.id
      const modelId = this.$store.state.model.id
      this.$http.get(`/api/v2/domain_model/get_component_interfaces/${compID}`, { params: { modelId } })
        .then(response => {
          console.debug(response)
          this.tempInterfaces = response.data
        })
    },

    showInterface(interfaceId) {
      console.log('Route to: ', interfaceId)
      this.$router.push(
        {
          name: 'interfaces',
          query: { focus: interfaceId },
        },
      )
    },

    // Find all instances of being used as a resource
    findResources() {
      const output = {
        resources: [],
        if_resources: [],
        count: 0,
      }

      // Interfaces resource
      const rels = this.selected_entity2.context.relationships
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].labels.includes('Interface')) {
          if (rels[i].rel_type === 'RESOURCE') {
            output.if_resources.push(rels[i])
            output.count += 1
          }
        }
      }

      return output
    },

    // Route to the Issues view details panel correctly
    focusIssue(issueId) {
      this.$store.dispatch('issues/selectIssue', issueId)
        .then(() => {
          this.$router.push({
            name: 'issue_details',
            params: { id: issueId },
          })
        })
    },
    linkCptRequirements(evt, ents) {
      evt.preventDefault()
      this.selectedReqs = ents.map(x => ({
        id: x.value,
        display_id: x.display_id,
        priority: x.priority,
        object_text: x.object_text,
      }))
      const selectedId = this.selected_entity2.context.details.id
      const modelId = this.$store.state.model.id
      this.loading_status = true
      this.$http.post('/api/v2/domain_model/disassociate_all_requirements_from_component', { component: selectedId, model: modelId }).then(() => {
        this.$http.post('/api/v2/domain_model/associate_requirements_with_component', {
          components: [selectedId],
          requirements: this.selectedReqs.map(x => x.id),
          model: modelId,
        }).then(() => {
          this.$store.dispatch('domainModel/selectEntity2', selectedId)
          this.loading_status = false
        })
      })
      this.$bvModal.hide('associate-cpt-req-modal')
    },
    associateRequirementComponent(reqs) {
      const payload = {
        model: this.$store.state.model.id,
        requirements: reqs,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/domain_model/components/${this.selected_entity2.context.details.id}/requirements`, payload)
        .then(() => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
            .then(() => {
              this.syncAllocations()
            })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirements associated',
              text: 'Successfully associated Requirements with the Component',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Requirements',
              text: `An error occurred when attempting to associate Requirements with the Component.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    associateIssueComponent(issues) {
      const payload = {
        issues,
        target_id: this.selected_entity2.context.details.id,
        label: 'Component',
      }
      this.$store.dispatch('issues/allocateIssues', payload).then(() => {
        this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
          .then(() => this.syncAllocations())
      })
    },
    // Route to Behaviour Tree
    routeToBehaviourView(btId, bns, bt) {
      this.$store.dispatch('behaviours/selectBehaviourTree', btId)
        .then(() => {
          if (bns && bns.length > 0) {
            this.$router.push(
              {
                name: 'joint_mbse_tree_focus',
                params: { behaviourTreeId: btId },
                query: { focus: bns[0] },
              },
            )
          } else {
            this.$router.push(
              {
                name: 'joint_mbse_tree',
                params: { behaviourTreeId: btId },
              },
            )
          }
        })
    },
    async deleteInstance(node) {
      this.tempData = node
      await Vue.nextTick()
      this.$bvModal.show('delete-instance-modal')
    },
    async updateInstance(node) {
      this.tempData = node
      await Vue.nextTick()
      this.$bvModal.show('update-instance-modal')
    },
    refreshEntityContext() {
      this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        .then(() => {
          this.syncAllocations()
        })
    },

    associateReqModal() {
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allReqs = data
        })
      this.$bvModal.show('associator-generic-requirements')
    },
    associateIssueModal() {
      this.$store.dispatch('issues/getIssues').then(() => {
        this.allIssues = this.$store.state.issues.issues
      })
      this.$bvModal.show('associator-generic-issues')
    },
  },
}
</script>

<style lang="css">
#domain-entity-context .list-group {
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.disabled-item:active {
  background-color: inherit !important;
  color: inherit !important;
}
.disabled-item:hover {
  background-color: inherit !important;
}
</style>
