<template>
  <div v-if="selected_constraint">
    <b-modal
      id="delete-constraint-modal"
      title="Delete Quality Attribute Requirement"
      size="lg"
      @ok="deleteSelectedConstraint"
    >
      <p>
        Are you sure you want to delete the following quality attribute requirement?
      </p>
      <p>
        <span class="text-primary font-weight-bolder">{{ selected_constraint.name }}</span>
      </p>

      <p class="text-danger font-weight-bolder">
        This action cannot be undone.
      </p>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="outline-dark" @click="cancel()">
          No, keep it
        </b-button>
        <b-button variant="danger" @click="ok()">
          Yes, delete it
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_constraint']),
  },
  methods: {
    deleteSelectedConstraint(evt) {
      evt.preventDefault()
      this.$http.post('/api/v2/domain_model/del_constraint', { id: this.selected_constraint.id, model: this.$store.state.model.id }).then(() => {
        const selectedId = this.selected_entity2.context.details.id
        this.$bvModal.hide('delete-constraint-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
      })
    },

  },
}
</script>
