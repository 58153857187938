<template>
    <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
  <b-modal
    v-if="selected_entity && selected_entity.context"
    id="add-entity-modal"
    title="Add Entity"
    size="lg"
    ok-title="Add entity"
    ok-variant="success"
    :ok-disabled="invalid"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @shown="setData"
    @ok="onSubmit"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col cols="8">
          <label for="add_entity_input_1">Entity name</label>
            <validation-provider
              v-slot="{ errors }"
              vid="entity_name"
              name="Entity name"
              rules="required"
            >

          <b-form-input
              id="add_entity_input_1"
              v-model="retainedFormData.name"
              placeholder="Entity name..."
              :state="errors.length > 0 ? false:null"
              @update="validate"
              @focusout="validate"/>
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <label for="add_entity_input_2">Acronym</label>
          <b-form-input id="add_entity_input_2" v-model="formData.acronym" placeholder="Acronym..." />
        </b-col>
      </b-row>

      <label for="add_entity_rich_1">Description</label>
      <tip-tap-editor
        id="add_entity_rich_1"
        v-model="formData.description"
        placeholder="Describe this entity..."
      />

      <b-row class="mt-1">
        <b-col>
          <b-form-group>
            <label for="entity_type">Relationship type</label>
            <b-form-radio-group
              id="entity_type"
              v-model="formData.type"
              :options="[
                {text: 'Aggregation', value: 'aggregation'},
                {text: 'Inheritance', value: 'inheritance'},
              ]"
              class="mt-25 ml-50"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <label for="add_entity_input_3">Multiplicity</label>
          <b-form-input id="add_entity_input_3" v-model="retainedFormData.multiplicity" />
        </b-col>
      </b-row><hr>
      <b-row class="mt-1">
        <b-col>
          <label for="add_entity_select_1">Stereotype</label>
          <b-form-select
            id="add_entity_select_1"
            v-model="formData.labels"
            :options="stereotypes"
            multiple
            style="min-height: 8rem;"
          />
        </b-col>
        <b-col>
          <label for="add_entity_select_2">Classification</label>
          <b-form-select
            id="add_entity_select_2"
            v-model="formData.classification"
            :options="securityClassifications"
            style="min-height: 8rem;"
          />
        </b-col>
      </b-row>

    </b-form>
  </b-modal>
</ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    TipTapEditor,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    formData: {
      acronym: '',
      description: '',
      type: 'aggregation',
      labels: [],
      classification: '',
    },
    retainedFormData: {
      name: '',
      multiplicity: '0..*',
    },
    shown: false,
  }),
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
      securityClassifications: 'constants/securityClassifications',
    }),
  },
  mounted() {
    this.formData.labels = this.selected_entity?.context.labels
  },
  updated() {
    if (!this.shown) return
    const headerEle = document.querySelector('.modal-header')
    const modalEle = document.querySelector('.modal-dialog')
    let dragging = false
    const dragOffset = { left: 0, top: 0 }
    function mouseDownListener(ev) {
      dragging = true
      const rect = modalEle.getBoundingClientRect()
      dragOffset.left = ev.clientX - rect.left
      dragOffset.top = ev.clientY - rect.top
    }
    function mouseUpListener(ev) {
      dragging = false
    }
    function mouseMoveListener(ev) {
      if (dragging) {
        modalEle.style.position = 'fixed'
        modalEle.style.left = `${ev.clientX - dragOffset.left}px`
        modalEle.style.top = `${ev.clientY - dragOffset.top}px`
      }
    }
    headerEle.addEventListener('mousedown', mouseDownListener)
    document.addEventListener('mousemove', mouseMoveListener)
    document.addEventListener('mouseup', mouseUpListener)
  },
  created() {
    // Check if there's previous modal data in localStorage
    const previousData = JSON.parse(localStorage.getItem('previousModalData'))
    if (previousData) {
      // Use previous modal data if it exists
      this.retainedFormData = previousData
    }
  },
  methods: {
    validate() {
      this.$refs.validationObserver
        .validate()
        .then(isValid => {
          this.$http.post('/api/v2/domain_model/validate_component_name',
            {
              name: this.retainedFormData.name,
              model: this.$store.state.model.id})
            .then(({ data }) => {
              this.$refs.validationObserver.setErrors(data.errors)
            })
            .catch(e => console.error(e))
        })
    },
    setData() {
      if (this.selected_entity?.context) {
        const lbls = this.selected_entity?.context.labels
        this.formData.labels = lbls
        if (typeof lbls === 'string') {
          this.formData.labels = lbls.replace(/'/g, '"')
          this.formData.labels = JSON.parse(lbls)
        }
        if (lbls
          && (
            lbls.includes('Function')
            || lbls.includes('Capability')
            || lbls.includes('Activity')
          )
        ) {
          this.retainedFormData.multiplicity = 1
        }
        this.shown = true
      }
      this.formData.classification = this.$store.state.model.defaultSecurityClassification
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.shown = false
      if (this.formData.labels && this.formData.labels.indexOf('Component') === -1) {
        this.formData.labels.unshift('Component')
      }
      const params = {
        model: this.$store.state.model.id,
        parent_rel: this.formData.type,
        parent: this.selected_entity.context.details.id,
        ...this.formData,
        ...this.retainedFormData,
      }
      this.$http.post('/api/v2/domain_model/composition', params)
        .then(({ data }) => {
          this.$emit('added', data.id)
          localStorage.setItem('previousModalData', JSON.stringify(this.retainedFormData))
          this.$bvModal.hide('add-entity-modal')
          // this.clearForm()
          // DO NOT Clear the form for adding components -
          // it is best to leave as usually add many of the same stereotype at once (e.g. Functional/System Decomp)
        }).catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Entity',
              text: `An error occurred when attempting to update Entity.
              Server returned Status ${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
