<template>
  <b-modal
    v-if="component && component.context && component.context.details"
    id="generate-fn-requirements-modal"
    :title="`Generate functional requirements for ${component.context.details.name}`"
    size="xl"
    class="p-0"
    @show="onShow"
    @ok="generateFunctionalRequirements()"
  >
    <div>
      <p class="font-medium-1 mt-0">
        Restrict generation to functions used in selected Behaviour Trees (no selection = "all")
      </p>
      <v-select
        v-model="fields.restrict_to"
        placeholder="-- Select Behaviour Tree(s) --"
        label="name"
        :options="behaviourTrees"
        multiple
      >
        <template #option="{ name, status }">
          <div class="d-flex align-items-center">
            <span>{{ name }}</span>
            <span class="font-small-3 text-muted ml-1">Status: {{ status }}</span>
          </div>
        </template>
      </v-select>
    </div>
    <div>
      <GenerateRequirementForm
        v-model="fields"
        :component="component"
        type="functional"
      />
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button :disabled="!fields.specification" variant="primary" @click="ok()">
        Generate
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GenerateRequirementForm from '@/components/Domain/Forms/GenerateRequirementForm.vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: 'GenerateRequirementsFn',
  components: {
    vSelect,
    GenerateRequirementForm,
  },
  data() {
    return {
      fields: {
        type: 'story',
        specification: null,
        dest_target: null,
        selected_performers: [],
        selected_objectives: [],
        selected_standards: [],
        selected_enablers: [],
        selected_beneficiaries: [],
        allowed_performers: [],
        allowed_enablers: [],
        restrict_to: [],
        direction: 'after',
        include_qa: false,
      },
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      behaviourTrees: state => state.behaviourTrees.behaviourTrees,
    }),
    component() {
      return this.selected_entity
    },
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected_entity(newVal) {
      const t = this.fields
      t.selected_performers = newVal.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source)
      t.allowed_performers = []
      t.selected_beneficiaries = newVal.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.target)
      t.selected_enablers = newVal.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.target)
      t.allowed_enablers = []
      t.selected_objectives = newVal.context.relations.edges.filter(e => e.name === 'Contributes to').map(x => x.target)
      t.selected_standards = newVal.context.relations.edges.filter(e => e.name === 'Performed iaw').map(x => x.target)
      t.selected_allocations = newVal.context.relations.edges.filter(e => e.name === 'Allocated to').map(x => x.target)
    },
  },
  methods: {
    onShow() {
      this.fetchBehaviourTrees()
    },
    fetchBehaviourTrees() {
      this.loading = true
      this.$store
        .dispatch('behaviourTrees/getAllBehaviourTrees')
        .then(() => { this.$store.dispatch('behaviourTrees/getBehaviourTreesListMetadata') })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions({
      generateFunctionalRequirements_vuex: 'domainModel/generateFunctionalRequirements',
    }),
    generateFunctionalRequirements() {
      console.debug('Calling domainModel/generateFunctionalRequirements', this.fields)
      this.$store.dispatch('domainModel/generateFunctionalRequirements', {
        function: this.component.context.details.id,
        dest_spec: this.fields.specification,
        dest_target: this.fields.dest_target,
        direction: this.fields.direction,
        type: this.fields.type,
        performers: this.fields.selected_performers,
        enablers: this.fields.selected_enablers,
        beneficiaries: this.fields.selected_beneficiaries,
        objectives: this.fields.selected_objectives,
        standards: this.fields.selected_standards,
        allowed_performers: this.fields.allowed_performers,
        allowed_enablers: this.fields.allowed_enablers,
        include_qa: this.fields.include_qa,
        restrict_to: this.fields.restrict_to,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Requirements generated for ${this.component.context.details.name} in ${this.fields.specification}`,
            icon: 'CheckIcon',
            text: 'Requirements Generated',
            variant: 'success',
          },
        })
        this.$bvModal.hide('generate-fn-requirements-modal')
      })
    },
  },
}
</script>

<style scoped>

</style>
