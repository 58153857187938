<template>
  <b-modal
    id="add-release-allocate-modal"
    :title="'Create and Allocate Release'"
    size="lg"
    @ok="submitFunction"
  >
    <h4>New Release Record</h4>
    <b-form>
      <b-row>
        <b-col>
          <label for="add-allocate-release-name">Name:</label>
          <b-form-input id="add-allocate-release-name" v-model="name" type="text" required />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="add-allocate-release-desc">Description:</label>
          <b-form-textarea
            id="add-allocate-release-desc"
            v-model="description"
            placeholder="Enter description for release..."
            rows="6"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="add-allocate-release-date-start">Start Date of Release:</label>
          <b-input-group class="mb-3">
            <b-form-input
              id="add-allocate-release-date-start"
              v-model="start_date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="start_date"
                button-only
                right
                locale="en-AU"
                aria-controls="add-allocate-release-date-start"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="add-allocate-release-end-date">End Date of Release:</label>
          <b-input-group class="mb-3">
            <b-form-input
              id="add-allocate-release-end-date"
              v-model="end_date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="end_date"
                button-only
                right
                locale="en-AU"
                aria-controls="add-allocate-release-date-start"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Loading...
        </span>
        <span v-else>
          Ok
        </span>
      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    status: '',
    loading_status: false,
  }),
  computed: {
    ...mapState('domainModel', ['releases', 'selected_entity2']),
  },
  mounted() {
    this.$store.dispatch('releases/getReleases')
  },
  methods: {
    submitFunction() {
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const modelId = this.$store.state.model.id
      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        description: this.description,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
      }
      this.$http.post('/api/v2/releases', payload)
        .then(({ data }) => {
          this.$http.post('/api/v2/domain_model/associate_releases_with_component', {
            component: selectedId,
            model: modelId,
            releases: [data.id],
          })
            .then(() => {
              this.$store.dispatch('releases/getReleases')
              this.$store.dispatch('domainModel/selectEntity2', selectedId)
              this.$bvModal.hide('add-release-allocate-modal')
              this.$bvModal.hide('allocate-release-modal')
              this.loading_status = false
            })
        })
    },
  },
}
</script>
