<template>
  <b-modal
    id="edit-compliance-modal"
    title="Edit Compliance Requirement"
    size="lg"
    @ok="submitFunction"
    @show="getData"
  >
    <b-tabs>
      <b-tab title="Requirement">
        <b-form-group
          id="input-comply-with"
          label="Compliance with Standard / Reference"
          label-for="ddl-comply-with"
          description="Select an existing Standard / Reference to comply with or create a new one."
        >
          <b-form-input
            id="datalist-comply-standard"
            v-model="reference"
            :disabled="isUpdating"
            list="standards-list"
          />
          <b-form-datalist id="standards-list">
            <option v-for="std in standards" :key="std.id">
              {{ std.qualified_name }}
            </option>
          </b-form-datalist>
        </b-form-group>

        <b-form-group
          id="input-security-classification"
          label="Security Classification"
          label-for="ddl-security-classification"
          description="Set the security classification for this compliance requirement."
        >
          <b-form-select
            id="ddl-security-classification"
            v-model="classification"
            :options="securityClassifications"
            :disabled="isUpdating"
            required
          />
        </b-form-group>

        <hr class="mt-2">

        <list-group-requirement
          label="Requirements"
          :entity-array="requirements"
          modal="associator-generic-requirements-edit"
        />
      </b-tab>

      <b-tab title="Qualification Records">
        <list-group-entity
          class="w-100"
          label="Qualification Records"
          :is-create="true"
          :entity-array="qualificationRecords"
          modal="create-qualification-record-modal"
        >
          <template #icon>
            <feather-icon icon="PlusIcon" />
          </template>
          <template #body="{ array }">
            <div class="mw-100 scroll-400">
              <record-list-group-item
                v-for="record in array"
                :key="record.id"
                :records-item="record"
                @update-qualification-record="openUpdateQrModal"
                @delete-qualification-record="openDeleteQrModal"
                @link-evidence-with-qualification-record="openLinkEvidenceModal"
              />
            </div>
          </template>
        </list-group-entity>
      </b-tab>
    </b-tabs>

    <!-- Qualification Record Modals -->
    <div>
      <create-qualification-record-modal :parent-id="selected_compliance.id" @on-create="onQrUpserted" />
      <div v-if="selectedQualificationRecordId">
        <update-qualification-record-modal :qr-id="selectedQualificationRecordId" @on-update="onQrUpserted" />
        <delete-qualification-record-modal :qr-id="selectedQualificationRecordId" @on-delete="onQrDeleted" />
        <link-evidence-with-qualification-record :qr-id="selectedQualificationRecordId" />
      </div>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Discard
      </b-button>

      <b-button
        variant="success"
        :disabled="loading_status || reference === ''"
        @click="ok()"
      >
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Editing Compliance Requirement...
        </span>
        <span v-else>
          Edit Compliance Requirement
        </span>
      </b-button>
    </template>

    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      suffix="-edit"
      :associated-items="requirements.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.object_text.replace(/<\/?[^>]+(>|$)/g, '')}`,
        }
      })"
      :all-items="allRequirements.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.display_id,
          },
          text: `${item.display_id}. ${item.text.replace(/<\/?[^>]+(>|$)/g, '')}`,
        }
      })"
      @associated="linkRequirements"
    />
  </b-modal>
</template>

<script>
import router from '@/router'
import { mapGetters, mapState } from 'vuex'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import {
  BButton, BFormGroup, BFormInput, BModal, BSpinner,
} from 'bootstrap-vue'
import RecordListGroupItem from '@/views/OQE/RecordsModals/RecordListGroupItem.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import CreateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/CreateQualificationRecordModal.vue'
import UpdateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/UpdateQualificationRecordModal.vue'
import DeleteQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/DeleteQualificationRecordModal.vue'
import LinkEvidenceWithQualificationRecord from '@/components/ObjectiveQualificationEvidence/Record/LinkEvidence.vue'
import { nextTick } from '@vue/composition-api'

export default {
  components: {
    CreateQualificationRecordModal,
    UpdateQualificationRecordModal,
    DeleteQualificationRecordModal,
    LinkEvidenceWithQualificationRecord,
    ListGroupEntity,
    RecordListGroupItem,
    BSpinner,
    BModal,
    BFormInput,
    BButton,
    ListGroupRequirement,
    AssociatorGeneric,
  },
  data() {
    return {
      model: this.$store.state.model.id,
      selectedModel: this.$store.state.model.id,
      classification: this.$store.state.model.defaultSecurityClassification,
      reference: null,
      requirements: [],
      allRequirements: [],
      qualificationRecords: [],
      selectedQualificationRecordId: '',
      loading_status: false,
      isLoading: false,
      isUpdating: false,
    }
  },
  computed: {
    ...mapState({
      selected_entity2: state => state.domainModel.selected_entity2,
      selected_compliance: state => state.domainModel.selected_compliance,
      components: state => state.domainModel.components,
      standards: state => state.domainModel.standards,
      defaultSecurityClassification: state => state.model.defaultSecurityClassification || state.constants.defaultSecurityClassification.id,
    }),
    ...mapGetters({
      qualityAttributes: 'constants/qualityAttributes',
      securityClassifications: 'constants/securityClassifications',
    }),
    allRequirementsMapped() {
      return this.allRequirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.text?.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
    selectedRequirementsMapped() {
      return this.requirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.text?.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
  },
  methods: {
    getData() {
      this.fillFields(this.selected_compliance)
      this.loading_status = false
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allRequirements = data
        })
    },
    fillFields(val) {
      if (val === null) return
      const cur = this
      if (val.reference) {
        cur.reference = val.reference.qualified_name
      } else {
        cur.reference = ''
      }
      cur.classification = val.classification || this.$store.state.model.defaultSecurityClassification
      cur.requirements = val.requirements || []
      cur.qualificationRecords = val.qualification_records || []
    },
    submitFunction(e) {
      e.preventDefault()
      this.isUpdating = true
      const { modelId } = router.currentRoute.params
      const selectedId = this.selected_compliance.id
      const payload = {
        reference: this.reference,
        classification: this.classification,
        requirements: this.requirements,
        qualification_records: this.qualificationRecords,
      }
      this.$http
        .post(`/api/v2/domain_model/compliance_requirement/${selectedId}`, payload, { headers: { 'Model-Id': modelId } })
        .then(() => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isUpdating = false
          this.$bvModal.hide('edit-compliance-modal')
        })
    },
    linkRequirements(reqs) {
      const temp = this.findItemById(reqs, this.allRequirements)

      this.requirements = temp.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.text,
        priority: x.priority,
      }))
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
    openUpdateQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`update-qualification-record-${qrId}`)
      })
    },
    openDeleteQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`delete-qualification-record-${qrId}`)
      })
    },
    openLinkEvidenceModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`link-evidence-with-qualification-record-${qrId}`)
      })
    },
    onQrDeleted() {
      if (this.qualificationRecords) {
        this.qualificationRecords = this.qualificationRecords.filter(obj => obj.id !== this.selectedQualificationRecordId)
      }
    },
    onQrUpserted(qrObj) {
      if (this.qualificationRecords) {
        const existing = this.qualificationRecords.find(obj => obj.id === qrObj.id)
        if (existing) {
          this.qualificationRecords = this.qualificationRecords.filter(obj => obj.id !== qrObj.id)
        }
        this.qualificationRecords.push(qrObj)
        this.qualificationRecords = this.qualificationRecords.toSorted(
          (a, b) => new Date(a.created).valueOf() - new Date(b.created).valueOf(),
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
</style>
