<template>
  <b-modal
    id="edit-relation-modal"
    title="Edit Relation"
    size="lg"
    @show="onShow"
    @ok="submitFunction()"
  >
    <b-form-group label="Relation Name:" label-for="rel-name-edit">
      <b-form-input
        id="rel-name-edit"
        v-model="data.name"
        list="common-rels-e"
        required
      />
      <datalist id="common-rels-e">
        <option>Accountable for</option>
        <option>Associated with</option>
        <option>Allocated to</option>
        <option>Benefits</option>
        <option>Benefits from</option>
        <option>Consulted on</option>
        <option>Consumes</option>
        <option>Consumed by</option>
        <option>Contributes to</option>
        <option>Enables</option>
        <option>Enabled by</option>
        <option>Hosts</option>
        <option>Hosted on</option>
        <option>Implements</option>
        <option>Implemented by</option>
        <option>Implemented on</option>
        <option>Implemented at</option>
        <option>Informed of</option>
        <option>Is</option>
        <option>Is a</option>
        <option>Performs</option>
        <option>Performed by</option>
        <option>Produces</option>
        <option>Produced by</option>
        <option>Provides</option>
        <option>Provided by</option>
        <option>Realises</option>
        <option>Realised by</option>
        <option>Responsible for</option>
        <option>Uses</option>
      </datalist>
    </b-form-group>
    <b-row>
      <b-col>
        <b-form-group label="Source Entity:" label-for="input-2">
          <b-form-select
            id="input-2"
            v-model="source"
            :options="comp_tree"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Source Multiplicity:" label-for="input-3">
          <b-form-input id="input-3" v-model="data.source_multiplicity" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Source Label:" label-for="input-4">
          <b-form-input id="input-4" v-model="data.source_label" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Target Entity:" label-for="input-5">
          <b-form-select
            id="input-5"
            v-model="target"
            :options="comp_tree"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Target Multiplicity:" label-for="input-6">
          <b-form-input id="input-6" v-model="data.target_multiplicity" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Target Label:" label-for="input-7">
          <b-form-input id="input-7" v-model="data.target_label" />
        </b-form-group>
      </b-col>
    </b-row>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="primary" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Updating...
        </span>

        <span v-else> Update </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
  },
  data() {
    return {
      loading_status: false,
      comp_tree: [],
      source: '',
      target: '',
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    data(newVal) {
      this.fillFields(newVal)
    },
  },
  methods: {
    onShow() {
      if (this.data) this.fillFields(this.data)
      const modelId = this.$store.state.model.id
      // TODO: Remove after fixed API
      this.$http.get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.comp_tree = [
            {
              value: null,
              text: 'Please select an option',
              disabled: true,
            },
            ...data.map(x => ({
              value: x.id,
              text: x.name,
            })),
          ]
        })
    },
    fillFields(val) {
      this.source = val.source
      this.target = val.target
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.comp_tree = [
            {
              value: null,
              text: 'Please select an option',
              disabled: true,
            },
            ...data.map(x => ({
              value: x.id,
              text: x.name,
            })),
          ]
        })
    },
    submitFunction() {
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        model: this.$store.state.model.id,
        name: this.data.name,
        rel_id: this.data.id,
        source: this.source,
        source_label: this.data.source_label,
        source_multiplicity: this.data.source_multiplicity,
        target: this.target,
        target_label: this.data.target_label,
        target_multiplicity: this.data.target_multiplicity,
      }

      this.$http.post('/api/v2/domain_model/comp_rels', payload).then(() => {
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
        this.loading_status = false
        this.$bvModal.hide('edit-relation-modal')
      })
    },
  },
}
</script>
